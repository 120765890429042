import React, { Component } from "react"
import { Link } from "gatsby"
import CareersImage from "../components/images/CareersBackground"
// material ui items
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
//my items
import Pdf from "../documents/ADC_Application.pdf"

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

export class careers extends Component {
  render() {
    const { classes } = this.props
    return (
      <Layout>
        <div>
          <div style={{ maxHeight: "100vh" }}>
            <div className="hero-image">
              <div className="wrap">
                <h1 className="hero-text  ">
                  <div className="header-subpage">CAREERS</div>
                </h1>
                <h2 className="hero-text"> </h2>
                <CareersImage />
              </div>
            </div>
          </div>
          <div className="container-body container-centered container-margin">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} style={{ margin: "1em 0 1em" }}>
                <Typography variant="h4" className="container" gutterBottom>
                  {" "}
                  Current Job Listings{" "}
                </Typography>
                <div className="container">
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/carpenter"
                    className={classes.button}
                    className="links-body"
                  >
                    Carpenter
                  </Button>

                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/laborer"
                    className={classes.button}
                  >
                    Laborer
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h6" className="container" gutterBottom>
                  {" "}
                  Don't see a position that fits you? Send us your resume for
                  future consideration!
                </Typography>

                <div className="container-button">
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    href={Pdf}
                    download
                    className={classes.button}
                    style={{ maxWidth: "80%" }}
                  >
                    Fillable PDF Application
                  </Button>
                </div>
                <div className="container-button">
                  <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    href="mailto:info@adcalaska.com"
                  >
                    Attach and Submit{" "}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="container-body">
            <Typography variant="h4" className="container-margin">
              Equal Opportunity Employer
            </Typography>

            <Typography paragraph className="container-margin">
              American Dream Construction, LLC. is an equal opportunity
              employer. As such, American Dream does not discriminate in
              employment opportunities or practices on the basis of race, color,
              religion, sex, pregnancy, sexual orientation, martial status,
              veteran status, national origin, age, physical or mental
              disability, or any other characteristic protected by law.
            </Typography>
            <Typography variant="h4" className="container-margin">
              Benefits
            </Typography>
            <Typography paragraph className="container-margin">
              Eligible employees at American Dream are provided a wide range of
              benefits. A number of the programs cover all employees in the
              manner prescribed by the law such as Social Security, workers'
              compensation, state disability, and unemployment insurance. We know the importance of our employees and their
              families, therefore we are proud to offer benefits that allow them
              family time and to be involved with a community conscience
              company.
              <br />
              <br />
              <ul>
                <li>Safety Training</li>
                <li> Safety Conscious Workplace</li>
                <li> Earned Vacation and Sick Time</li>
                <li> Drug Free Workplace</li>
                <li> Career Growth Opportunity</li>
                <li> Holiday Time Off </li>
                <li> Direct Deposit </li>
                <li> Travel-Mileage Reimbursement </li>
              </ul>
            </Typography>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(careers)
